$brand-color: #1595CE;
//$brand-color: #4c4c4c;
$secondary-brand-color: #0d5c7d;
//$secondary-brand-color: #121212;
$middle-gradient-color: mix($brand-color, $secondary-brand-color, 95%);

// Breakpoints
$tablet: "(min-width: 450px)";
$mid-point: "(min-width: 620px)";
$desktop: "(min-width: 768px)";
