.plans {
	@extend %flexbox;
	@include flex-flow(wrap);
	padding: 50px 0 30px 0;

	.plan {
	      display: flex;
          flex-direction: column;
          flex: 1;
          border: 1px solid black;
          padding: 0;
          list-style: none;
        border: 1px solid black;
        padding: 0;
        list-style: none;
		padding: 0;
		margin: 0 10px 50px 10px;
		text-align: center;
		border: 1px solid #eee;
		border-radius: 5px;
		box-shadow: 0px 0px 10px #eee;
		width: 100%;

		.highlighted {
			font-size: 1.2em
		}

		.pricing-cta {
			padding: 0;

			a {
				display: block;
				box-sizing: border-box;
				padding: 20px 0;
				border-radius: 0 0 2px 2px;
				border: 0;
			}
		}

		@media #{$desktop} {
			-webkit-flex: 1;
			flex: 1;
		}

		li {
			border-top-right-radius: 5px;
			border-top-left-radius: 5px;
			padding: 20px 0;
			h3 {
				padding: 0;
				margin: 0;
				color: #fff;
				font-weight: normal;
			}
		}
	}
}

.faq {
	@media #{$desktop} {
		@include columns(2);
	}
	color: #666;
	div {
		break-inside: avoid;
		padding: 25px 0;
	}

	dt {
		font-weight: bold;
		margin: 0 0 5px 0;
	}

	dd {
		padding: 0;
		margin: 0;

	}
}
 .article-card {
      width: 300px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      text-align: center;
      overflow: hidden;
      margin:4px
    }

    .article-card .image-slider {
      position: relative;
      height: 400px;
      overflow: hidden;
    }

    .article-card .image-slider img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: transform 0.3s ease-in-out;
    }

    .article-card .image-slider img.active {
      transform: scale(1.1);
    }

    .article-card .image-slider .slider-nav {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
    }

    .article-card .image-slider .slider-nav button {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 5px;
      margin: 0 2px;
    }

    .article-card h3 {
      font-size: 18px;
      margin: 15px 0;
      color: #333;
    }

    .article-card p {
      font-size: 14px;
      color: #777;
      margin-bottom: 20px;
    }

    .article-card button {
      background-color: #2c9fd2;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;
      font-weight: bold;
      margin:10px
    }

.containerflex {
  display: flex;
  flex-wrap: wrap;
}

.itemflex {
  flex-basis: calc(33.33% -20%);
//   background-color: #f1f1f1;
//   padding: 20px;
//   margin: 10px;
}

:root {
  --primary-color: #007bff;
  --secondary-color: #ff7f50;
  --background-color-light: #f2f2f2;
  --background-color-dark: #dddddd;
}

.camera-configurator {
  display: flex;
  align-items: start;
  justify-content: start;
  background-color: var(--background-color-light);
  padding: 20px;
}

.camera-image {
  flex: 1;
  text-align: center;
}

.camera-image img {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.camera-details {
  flex: 1;
  margin-left: 20px;
}

.camera-name {
  font-size: 24px;
  font-weight: bold;
}

.camera-description {
  margin-top: 10px;
}

.camera-price {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.config-options {
  margin-top: 30px;
}

.option-category {
  margin-bottom: 20px;
}

.options {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.option {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.configure-button {
  margin-top: 30px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.configure-button:hover {
  background-color: var(--secondary-color);
}

@media (max-width: 768px) {
  .camera-configurator {
    flex-direction: column;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
