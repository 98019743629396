
// .main-container {
//   padding: 30px;
// }

/* HEADING */



.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
//   min-height:250px;

}

.column {
  flex: 1 0 30%;
  max-width: 30%;
  margin-bottom: 20px;
  padding-right: 10px;
  box-sizing: border-box;
}

.divider {
width: 2px;
    background-color: #fff;
    margin: 0 10px;
    align-self: stretch;
    padding-top: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 5px;


}

@media screen and (max-width: 768px) {
  .column {
    flex: 1 0 100%;
    max-width: 100%;
  }
  .divider {
    display: none;
  }
}




.heading {
  text-align: center;
}

.heading__title {
  font-weight: 600;
}

.heading__credits {
  margin: 10px 0px;
  color: #888888;
  font-size: 25px;
  transition: all 0.5s;
}

.heading__link {
  text-decoration: none;
}

.heading__credits .heading__link {
  color: inherit;
}

/* CARDS */

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  margin: 20px;
  padding: 20px;
  // width: 500px;
  // min-height: 200px;
  display: grid;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
  flex-grow: 1; 
  flex-shrink: 1;
}

.card:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
}

.card__link,
.card__exit,
.card__icon {
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
}

.card__link::after {
  position: absolute;
  top: 25px;
  left: 0;
  content: "";
  width: 0%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
}

.card__link:hover::after {
  width: 100%;
}

.card__exit {
  grid-row: 1/2;
  justify-self: end;
}

.card__icon {
  grid-row: 2/3;
  font-size: 30px;
}

.card__title {
  grid-row: 3/4;
  font-weight: 400;
  color: #ffffff;
}

.card__apply {
  grid-row: 4/5;
  align-self: center;
}

/* CARD BACKGROUNDS */

.card-1 {
  background: linear-gradient(#1595CE, #0d5c7d)
}

/* RESPONSIVE */

// @media (max-width: 1600px) {
//   .cards {
//     justify-content: center;
//   }
// }


.modal {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  background: rgba(0,0,0, .9);
  transition: opacity .25s ease;
}

.modal__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.modal-state {
  display: none;
}

.modal-state:checked + .modal {
  opacity: 1;
  visibility: visible;
}

.modal-state:checked + .modal .modal__inner {
  top: 0;
}

.modal__inner {
  transition: top .25s ease;
  position: absolute;
  top: -20%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  margin: auto;
  overflow: auto;
  background: $middle-gradient-color;
  border-radius: 5px;
  padding: 1em 2em;
  height: 50%;
}

.modal__close {
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.1em;
  height: 1.1em;
  cursor: pointer;
}

.modal__close:after,
.modal__close:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 1.5em;
  background: #ccc;
  display: block;
  transform: rotate(45deg);
  left: 50%;
  margin: -3px 0 0 -1px;
  top: 0;
}

.modal__close:hover:after,
.modal__close:hover:before {
  background: #aaa;
}

.modal__close:before {
  transform: rotate(-45deg);
}

@media screen and (max-width: 768px) {

  .modal__inner {
    width: 90%;
    height: 90%;
    box-sizing: border-box;
  }
}



//#content {
//
//  background:
//          linear-gradient(to right, white 4px, transparent 4px) 0 0,
//          linear-gradient(to right, white 4px, transparent 4px) 0 100%,
//          linear-gradient(to left, white 4px, transparent 4px) 100% 0,
//          linear-gradient(to left, white 4px, transparent 4px) 100% 100%,
//          linear-gradient(to bottom, white 4px, transparent 4px) 0 0,
//          linear-gradient(to bottom, white 4px, transparent 4px) 100% 0,
//          linear-gradient(to top, white 4px, transparent 4px) 0 100%,
//          linear-gradient(to top, white 4px, transparent 4px) 100% 100%;
//
//  background-repeat: no-repeat;
//  background-size: 20px 20px;
//  padding-bottom:5px;
//}

.bottom-cta {
//   background: linear-gradient(to bottom, $brand-color 0%, $middle-gradient-color 100%);
  color: #fff;
  text-align: center;
//   margin: 0;
//   padding: 100px 0;

  h2 {
    margin-bottom: 50px;
  }
}

body {
  font-family: 'Roboto', sans-serif;
  //font-size: 48px;
}

#registerbutton {

  border: 1px solid  $brand-color;
  border-radius: 10px;
  padding:10px;
  color:$middle-gradient-color;
  background:$brand-color
}


@keyframes jittery {
  5%,
  50% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.9);
  }

  15% {
    transform: scale(1.15);
  }

  20% {
    transform: scale(1.15) rotate(-5deg);
  }

  25% {
    transform: scale(1.15) rotate(5deg);
  }

  30% {
    transform: scale(1.15) rotate(-3deg);
  }

  35% {
    transform: scale(1.15) rotate(2deg);
  }

  40% {
    transform: scale(1.15) rotate(0);
  }
}

@keyframes jelly {
  25% {
    transform: scale(0.9, 1.1);
  }

  50% {
    transform: scale(1.1, 0.9);
  }

  75% {
    transform: scale(0.95, 1.05);
  }
}

@keyframes pulse {
  from {
    box-shadow: 0 0 0 0 var(--btn-bg);
  }
}

@keyframes move-left {
  to {
    transform: translateX(-100%);
  }
}

.testimonial {
  background: #f5f5f5;
  margin: 0;
  padding: 100px 0;

  .testimonial-block {
    max-width: 750px;
    width: 98%;
    margin: 0 auto;

    @media #{$tablet} {
      @include flexbox;

      blockquote {
        -webkit-flex: 1;
        flex: 1;
      }
    }
  }
}

#iframe1 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
}

//h1 {
//  background: rgba(0, 0, 0, 0.5);
//  padding: 10px;
//}

#text {
  position: absolute;
  color: #FFFFFF;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (min-aspect-ratio: 16/9) {
  .video-container iframe {
    /* height = 100 * (9 / 16) = 56.25 */
    height: 56.25vw;
  }
}

@media (max-aspect-ratio: 16/9) {
  .video-container iframe {
    /* width = 100 / (9 / 16) = 177.777777 */
    width: 177.78vh;
  }
}

.hero {
  color: #ffffff;
  text-align: center;
//   background: linear-gradient(to bottom, $middle-gradient-color 0%, $secondary-brand-color 100%) no-repeat #a05fb7;
  padding-top: 50px;

  p {
    color: #fff;
  }
}


@media #{$desktop} {
  .flex {
    @include flexbox;
    align-items: center;
    flex-direction: row;

    .text, .image {
      -webkit-flex: 1;
      flex: 1;
      padding: 0 20px;
    }
  }

  .content section:nth-child(even) .flex {
    flex-direction: row-reverse;
  }
}
